<template>
  <el-dialog
    :model-value="modelValue"
    width="500px"
    title="修改角色"
    @close="closed"
  >
    <el-form ref="formRef" :model="form" :label-width="100">
      <el-form-item label="角色名称" prop="roleName">
        <el-input
          v-model="form.roleName"
          placeholder="请输入角色名称"
        ></el-input>
      </el-form-item>
      <el-form-item label="角色标识" prop="roleCode">
        <el-input
          v-model="form.roleCode"
          placeholder="请输入角色标识"
        ></el-input>
      </el-form-item>
      <el-form-item label="备注" prop="remark">
        <el-input
          v-model="form.remark"
          type="textarea"
          placeholder="请输入备注"
        ></el-input>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onSubmitClick">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { ElMessage } from 'element-plus'
import { getSysRoleByRoleId, updateSysRole } from '@/api/sys-role'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  roleId: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'close', 'roleId'])

const formRef = ref(null)

const closed = () => {
  emits('update:modelValue', false)
  emits('close')
  formRef.value.resetFields()
}

const form = ref({})

const loading = ref(false)

/**
 * 获取当前角色id数据
 * @returns {Promise<void>}
 */
const getRoleData = async () => {
  form.value = await getSysRoleByRoleId(props.roleId)
}
getRoleData()

/**
 * 提交表单信息
 * @returns {Promise<void>}
 */
const onSubmitClick = async () => {
  loading.value = true
  await updateSysRole(form.value)
  ElMessage.success('添加成功')
  loading.value = false
  closed()
}
</script>

<style lang="scss" scoped></style>

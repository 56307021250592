export const createSysMenuTree = data => {
  const map = {}
  const val = []
  data.forEach(it => {
    map[it.menuId] = it
  })
  data.forEach(it => {
    const parent = map[it.parentId]
    if (parent) {
      if (!Array.isArray(parent.children)) parent.children = []
      parent.children.push(it)
    } else {
      val.push(it)
    }
  })
  return val
}

export const createTree = data => {
  const map = {}
  const val = []
  data.forEach(it => {
    map[it.id] = it
  })
  data.forEach(it => {
    const parent = map[it.parentId]
    if (parent) {
      if (!Array.isArray(parent.children)) parent.children = []
      parent.children.push(it)
    } else {
      val.push(it)
    }
  })
  return val
}

import request from '../utils/request'

/**
 * 获取系统菜单列表
 * @returns {AxiosPromise}
 */
export const getSysMenuList = data => {
  return request({
    url: '/sysMenu',
    params: data
  })
}

/**
 * 获取指定id的菜单数据
 * @param menuId
 * @returns {AxiosPromise}
 */
export const getSysMenuByMenuId = menuId => {
  return request({
    url: `/sysMenu/${menuId}`
  })
}

/**
 * 添加系统菜单
 * @param data
 * @returns {AxiosPromise}
 */
export const addSysMenu = data => {
  return request({
    url: '/sysMenu',
    method: 'POST',
    data
  })
}

/**
 * 更新系统菜单
 * @param data
 * @returns {AxiosPromise}
 */
export const updateSysMenu = data => {
  return request({
    url: '/sysMenu',
    method: 'PUT',
    data
  })
}

/**
 * 删除系统菜单
 * @param menuId
 * @returns {AxiosPromise}
 */
export const deleteSysByMenuId = menuId => {
  return request({
    url: `/sysMenu/${menuId}`,
    method: 'DELETE'
  })
}

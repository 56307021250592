<template>
  <div>
    <el-card>
      <div class="header">
        <el-form :inline="true" :model="queryForm" class="demo-form-inline">
          <el-form-item label="角色名称">
            <el-input
              v-model="queryForm.roleName"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item label="角色标识">
            <el-input
              v-model="queryForm.roleCode"
              placeholder="请输入"
            ></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="getListData">查询</el-button>
          </el-form-item>
        </el-form>
      </div>
      <div
        class="tool"
        style="background: #fafafa;display: flex;padding: 10px;border: 1px solid #ebeef5;border-bottom: 0;"
      >
        <div>
          <el-button size="small" type="primary" @click="addClick"
            >添加
          </el-button>
          <el-button size="small" type="danger" @click="deleteBatchClick"
            >删除</el-button
          >
        </div>
        <div style="flex: 1;text-align: right;">
          <el-button size="small" type="primary" @click="getListData" plain
            >刷新列表
          </el-button>
        </div>
      </div>
      <div class="table">
        <el-table
          border
          v-loading="loading"
          :header-cell-style="{
            background: '#fafafa',
            color: '#333333',
            padding: '4px 0'
          }"
          :cell-style="{ padding: '4px 0' }"
          :data="tableData"
          style="width: 100%"
          @selection-change="handleSelectionChange"
          @sort-change="handleSortChange"
        >
          <el-table-column type="selection" width="55" />
          <el-table-column property="roleName" label="角色名称" />
          <el-table-column property="roleCode" label="角色标识" />
          <el-table-column property="remark" label="备注" />
          <el-table-column
            property="createTime"
            sortable="custom"
            label="创建时间"
          />
          <el-table-column fixed="right" label="操作" width="150">
            <template #default="scope">
              <el-button
                type="text"
                size="small"
                @click="updateClick(scope.row)"
                :disabled="scope.row.roleId === 1"
                >修改
              </el-button>
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.roleId === 1"
                @click="setRoleMenuClick(scope.row)"
                >分配权限
              </el-button>
              <el-button
                type="text"
                size="small"
                :style="{
                  color: scope.row.roleId === 1 ? '#c0c4cc' : '#F56C6C'
                }"
                :disabled="scope.row.roleId === 1"
                @click="deleteClick(scope.row)"
                >删除
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div style="text-align: center;padding-top: 20px;">
        <el-pagination
          layout="total, sizes, prev, pager, next, jumper"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="queryForm.limit"
          :current-page="queryForm.page"
          :total="total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        >
        </el-pagination>
      </div>
    </el-card>

    <add-role
      v-if="addDialogVisible"
      v-model="addDialogVisible"
      @close="getListData"
    >
    </add-role>

    <edit-role
      v-if="updateDialogVisible"
      v-model="updateDialogVisible"
      :roleId="selectItemId"
      @close="getListData"
    >
    </edit-role>

    <set-role-menu
      v-if="setMenuDialogVisible"
      v-model="setMenuDialogVisible"
      :roleId="selectItemId"
    >
    </set-role-menu>
  </div>
</template>

<script setup>
import { ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import {
  getSysRolePage,
  deleteBatchSysRole,
  deleteSysRoleByRoleId
} from '@/api/sys-role'
import addRole from './components/AddRole'
import editRole from './components/EditRole'
import setRoleMenu from './components/SetRoleMenu'

const queryForm = ref({
  page: 1,
  limit: 10,
  sort: 'createTime',
  order: 'desc',
  roleName: '',
  roleCode: ''
})

/**
 * 表格数据相关
 */

const loading = ref(false)
const tableData = ref([])
const total = ref(0)
// 获取数据的方法
const getListData = async () => {
  loading.value = true
  const result = await getSysRolePage(queryForm.value)
  tableData.value = result.list
  total.value = result.total
  loading.value = false
}
getListData()

/**
 * 选择要操作的列表id
 */
const selectItemId = ref(0)

/**
 * 分页数量改变事件
 */
const handleSizeChange = val => {
  queryForm.value.limit = val
  getListData()
}
const handleCurrentChange = val => {
  queryForm.value.page = val
  getListData()
}

/**
 * 根据创建时间排序
 */
const handleSortChange = val => {
  queryForm.value.sort = val.prop
  console.log(val.order)
  if (val.order === 'ascending') {
    queryForm.value.order = 'asc'
  } else {
    queryForm.value.order = 'desc'
  }
  getListData()
}

/**
 * 选中的列表id数组
 */
const selectItemIds = ref([])
const handleSelectionChange = val => {
  selectItemIds.value = []
  val.forEach(row => {
    selectItemIds.value.push(row.roleId)
  })
}

/**
 * 显示添加窗口
 * @type {Ref<UnwrapRef<boolean>>}
 */
const addDialogVisible = ref(false)
const addClick = () => {
  addDialogVisible.value = true
}

/**
 * 批量删除
 */
const deleteBatchClick = () => {
  ElMessageBox.confirm('确认批量删除选中角色吗？', '批量删除', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteBatchSysRole(selectItemIds.value)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getListData()
    })
    .catch(() => {})
}
/**
 * 显示修改窗口
 * @type {Ref<UnwrapRef<boolean>>}
 */
const updateDialogVisible = ref(false)
const updateClick = row => {
  selectItemId.value = row.roleId
  updateDialogVisible.value = true
}

/**
 * 删除按钮点击事件
 * @param row
 */
const deleteClick = row => {
  ElMessageBox.confirm('确认删除[' + row.roleName + ']角色吗？', '删除', {
    confirmButtonText: '确认',
    cancelButtonText: '取消',
    type: 'warning'
  })
    .then(async () => {
      await deleteSysRoleByRoleId(row.roleId)
      ElMessage({
        type: 'success',
        message: '删除成功'
      })
      getListData()
    })
    .catch(() => {})
}

/**
 * 显示分配权限窗口
 */
const setMenuDialogVisible = ref(false)
const setRoleMenuClick = row => {
  selectItemId.value = row.roleId
  setMenuDialogVisible.value = true
}
</script>

<style scoped></style>

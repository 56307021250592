<template>
  <el-dialog
    :model-value="modelValue"
    width="500px"
    title="分配角色权限"
    @close="closed"
  >
    <div style="height: 400px;overflow:auto;">
      <el-tree
        :data="menuData"
        ref="treeRef"
        show-checkbox
        node-key="menuId"
        :default-checked-keys="roleMenuData"
        default-expand-all
        :props="menuProps"
      />
    </div>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="closed">取消</el-button>
        <el-button type="primary" @click="onSubmitClick">保存</el-button>
      </span>
    </template>
  </el-dialog>
</template>

<script setup>
import { defineEmits, defineProps, ref } from 'vue'
import { createSysMenuTree } from '@/utils/tree'
import { setRoleMenu, getRoleMenu } from '@/api/sys-role'
import { getSysMenuList } from '@/api/sys-menu'
import { ElMessage } from 'element-plus'

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true
  },
  roleId: {
    type: Number,
    required: true
  }
})

const emits = defineEmits(['update:modelValue', 'roleId'])

const closed = () => {
  emits('update:modelValue', false)
}

const treeRef = ref(null)

/**
 * 菜单数据
 * @type {ToRef<*[]>}
 */
const menuData = ref([])
const menuProps = ref({
  children: 'children',
  label: 'title'
})

/**
 * 查询角色所拥有的菜单权限
 */
const roleMenuData = ref([])
const getSysRoleMenuData = async () => {
  const result = await getRoleMenu(props.roleId)
  for (const menu of result) {
    // 判断是按钮权限类型则选中，否则菜单父节点选中会把子节点也全部选中
    if (menu.menuType === 1) {
      roleMenuData.value.push(menu.menuId)
    }
  }
  getSysMenuData()
}
getSysRoleMenuData()

/**
 * 查询全部菜单
 */
const getSysMenuData = async () => {
  menuData.value = await getSysMenuList()
  menuData.value = createSysMenuTree(menuData.value)
}

/**
 * 提交
 */
const onSubmitClick = async () => {
  const selectMenuIds = treeRef.value
    .getCheckedKeys()
    .concat(treeRef.value.getHalfCheckedKeys())
  await setRoleMenu(props.roleId, selectMenuIds)
  ElMessage.success('设置成功')
  closed()
}
</script>

<style lang="scss" scoped></style>
